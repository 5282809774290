import React from "react"
import { Link } from "gatsby"
import { Carousel, Container } from "react-bootstrap"

import Layout from "../components/Layout"
import styles from "./detail.module.css"

export default ({ data, pageContext }) => {
  function getActiveKeyFromPath(directoryPath) {
    if (directoryPath.includes("montaz")) return "0"
    if (directoryPath.includes("strecha")) return "1"
    if (directoryPath.includes("byty")) return "2"
    if (directoryPath.includes("holuby")) return "3"
    if (directoryPath.includes("firma")) return "4"
    if (directoryPath.includes("zatekanie")) return "5"
    if (directoryPath.includes("orezat")) return "6"
    if (directoryPath.includes("ostatne")) return "7"
    return "0"
  }

  function parseTitleFromContext(src) {
    return src.slice(src.lastIndexOf("/") + 1)
  }

  return (
    <Layout>
      <div className={styles.titleContainer}>
        <Link
          to={"./galeria"}
          state={{
            defaultActiveKey: getActiveKeyFromPath(pageContext.directoryPath),
          }}
        >
          <h5>Naspäť na Galériu</h5>
        </Link>
      </div>

      <Carousel interval={3000} className={styles.carousel}>
        {data.allFile.edges.map(edge => {
          const imageSrc = edge.node.childImageSharp.fluid.src

          return (
            <Carousel.Item
              className={styles.item}
              style={{
                backgroundImage: `url(${imageSrc})`,
              }}
            >
              <Container>
                <Carousel.Caption>
                  <h2 className={styles.caption}>
                    {parseTitleFromContext(pageContext.directoryPath)}
                  </h2>
                </Carousel.Caption>
              </Container>
            </Carousel.Item>
          )
        })}
      </Carousel>
    </Layout>
  )
}

export const query = graphql`
  query($directoryPath: String!) {
    allFile(filter: { relativeDirectory: { eq: $directoryPath } }) {
      edges {
        node {
          relativeDirectory
          childImageSharp {
            fluid {
              src
            }
          }
          id
        }
      }
    }
  }
`
